import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import DeleteIMG from "../../assets/images/delete.svg";
import Layout from "../../utils/layout/Layout.jsx";
import { useLocation } from "react-router-dom";
import "./500InternalError.scss";
import { redirectToLogout } from "utils/auth0/common";
import { useAppState } from "states/appState";

export default function InternalError() {
  const { t } = useTranslation();
  const location = useLocation();
  const [technicalErrorVisible, setTechnicalErrorVisible] = useState(false);
  const searchParams = new URLSearchParams(document.location.search);
  const errorDescription = searchParams.get("error_description");
  const errorKey = searchParams.get("error");

  const handleClick = (event) => {
    event.preventDefault();
    setTechnicalErrorVisible(!technicalErrorVisible);
  };

  const [appContext] = useAppState();

  useEffect(() => {
    document.title = t("metaTags.pagetitle.error500");
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      title={t("error.500.title")}
      containerClass="Profile-content--flCenter"
      iconHeaderRight="true"
    >
      <div className="Profile-image">
        <img src={DeleteIMG} alt="delete icon" />
      </div>
      <p className="Profile-text">
        {location?.state?.error_code
          ? t(location.state.error_code)
          : t("error.500.text")}
      </p>
      {errorDescription && errorKey && (
        <div className="CustomError">
          <div className="CustomError CustomError-container">
            <b>TECHNICAL DETAILS</b>
            <a href="#!" onClick={handleClick}>
              {technicalErrorVisible
                ? "Hide details for this error"
                : "See details for this error"}
            </a>
          </div>
          {technicalErrorVisible && (
            <div className="CustomError-details">
              {errorKey} : {errorDescription}
            </div>
          )}
        </div>
      )}
    </Layout>
  );
}
